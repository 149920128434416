<template>
  <div class="resize">
    <label class="resize-row standart-text" v-html="attachMessage"></label>
    <div class="l-col">
      <base-input :value="name" :readonly="true" @input="onChange"></base-input>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseInput from '@/components/BaseInput/BaseInput';

export default {
  name: 'DeattachDisk',
  components: { BaseInput },
  mixins: [setFocus],
  props: {
    instance: {
      type: String,
      required: true,
    },
    diskName: {
      type: String,
      required: true,
    },
    diskId: {
      type: String,
      required: true,
    },
  },
  computed: {
    name() {
      return `${this.serverName} (${this.instance})`;
    },
    serverName() {
      const server = this.$store.state.moduleStack.servers.find(x => x.id === this.instance);
      return server ? server.name : 'Неизвестный сервер';
    },
    attachMessage() {
      return this.$t('attach', {
        diskName: this.diskName,
        diskId: this.diskId,
        serverName: this.serverName,
        instance: this.instance,
      });
    },
  },
  methods: {
    onChange() {
      this.$emit('instance', this.instance);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "attach": "Отключить диск <b>%{diskName}</b> (%{diskId}) от сервера <b>%{serverName}</b> (%{instance})?",
    "sure": {
      "confirm": "Подключить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска",
    "notice": "Для вступления в силу - перезагрузите сервер"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;
  width: 100%;
  word-wrap: break-word;
  white-space: normal;

  &-row{
    display: block;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .75rem .75rem;
    text-align: left;
    max-width: 460px;
    &-input {
      padding: 1.5rem 1.5rem;
    }
  }
}
</style>
